<template>
    <tr class="clickable-row">
        <td><a href="">{{cashReport.id}}</a></td>
        <td><a href=""><span class="badge">{{StringsHelper.getChannel(cashReport.channel)}}</span></a></td>
        <td><a href="">{{Utils.getHourStringFromISO(cashReport.date)}}</a></td>
        <td><a href="">{{StringsHelper.getOfferta(cashReport)}}</a></td>
        <td><a href=""> <span v-if="cashReport.creator">{{StringsHelper.getClientStringNameFromReservation(cashReport)}}</span> </a></td>
        <td><a href=""> {{StringsHelper.getPaymentMode(cashReport.payment_mode)}}</a></td>
        <td class="text-right"><a href="">{{cashReport.price_final + " €"}}</a></td>
    </tr>
</template>

<script>
    
    import baseMixin from '@/common/baseMixin'
    export default {
        
        name: "CashReportRow",
        mixins: [baseMixin],
        props: {
            cashReport: {
                type: Object,
                required: true
            }
        } 
    }
</script>
