<template>
    <div class="col-xs-7 text-right">
		<label class="radio-inline" >
			<input type="radio" name="paymentOptions" :value="null" @change="filterPaymentChanged()" checked> Tutti
		</label>
		<label class="radio-inline" v-for="payment in paymentOptions" :key="payment.value">
			<input type="radio" name="paymentOptions" :value="payment.value" @change="filterPaymentChanged(payment.value)"> {{payment.name}}
		</label>
	</div>
</template>

<script>
	import baseMixin from '@/common/baseMixin'
	export default {
		
		name: "CashReportFilters",
		mixins: [baseMixin],
		props: {
			
		},
		mounted () {
			this.paymentOptions = this.Keys.PAYMENT_MODE_OPTIONS();
		},
		data: () => {
			return {
				paymentOptions: [],
			}
		},
		methods: {
			filterPaymentChanged(paymentMode=null) {
				this.$emit('filter-payment-changed', paymentMode)
			}
		}
	}
</script>