<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row hide-print">
				<div class="col-xs-6">
					<h1 class="h2">Riepilogo Cassa</h1>
				</div>
				<div class="col-xs-6 text-right">
					<a class="btn btn-default btn-sm" href="javascript:;" onclick="window.print();"><span class="glyphicon glyphicon-print" aria-hidden="true"></span> Stampa</a>
				</div>
			</div>
			<div class="row margin-bottom-20">
				<div class="col-xs-5">
					<span style="display:none;" id="today-date">{{Utils.getShortDateStringFromObj(new Date())}} </span>
					<span class="hide-print">Oggi hai incassato </span>
					<span style="display:none;" class="display-print"> - Totale incassi  </span>
					<b> €<span id="total_amount">{{this.total_amount}}</span></b>
				</div>
				<CashReportFilters @filter-payment-changed="paymentFilterChanged"/> 
			</div>
            <CashReportTable :cash-reports="cashReports"/>
        </div>
    </ContainerWithMenu>
    
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import CashReportTable from '@/components/cashReport/CashReportTable'
    import {getCashReportDay} from "../api"
    import baseMixin from "../common/baseMixin"
    import CashReportFilters from "@/components/cashReport/CashReportFilters"


    export default {
        title: "Riepilogo Cassa",
        name: "CashReport",
        mixins: [baseMixin],
        components: {ContainerWithMenu,CashReportTable,CashReportFilters},
        data: function() {
            return {
                loading: false,
                cashReports: [],
                total_amount: 0
            }
        },
        mounted() {
            this.getCashReportDay();
        },
        
        methods: {
            async getCashReportDay(paymentMode=null) {
                this.loading = true;
                try {
                    let cashReportsResponse = await getCashReportDay(paymentMode);
                    this.cashReports = cashReportsResponse.reservation_list;
                    this.total_amount = cashReportsResponse.total_amount;
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            paymentFilterChanged(paymentMode){
                this.getCashReportDay(paymentMode)
            }
            
        }
    };
</script>
